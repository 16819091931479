body {
   margin: 0;
   padding: 0;
   font-family:  'Raleway', 'Titillium Web', 'poppins', 'Exo 2', 'ubuntu' !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }


//Slider Ayarları
.carousel-item {
  position: relative;
  min-height: 600px;
}

.slider-button {
  border-radius: 25px;
  padding: 13px 30px;
  margin-right: 20px;
  margin-bottom: 15px;
  font-size: 20px;
}

.carousel-indicators li {
  background-color:#e0a028; //slider alt ikonları
}

.carousel-control-next-icon {
  background-color:#e0a028;
  width:40px;
  height:40px;
  padding: 20px;
  border-radius: 20%;
  position: absolute;
  right: 30px;
}

.carousel-control-prev-icon {
  background-color:#e0a028;
  width:40px;
  height:40px;
  border-radius: 20%;
  position: absolute;
  left: 30px;

}
nav-link {
  font-size: 18px !important;
  padding-left: 0.7rem !important;
  padding-right: 0.7rem !important; 
  } 

// Navbar Ayarları
.navbar {

 
  .nav-link.active {
    font-weight: bold;
    border-bottom: #e0a028 3px solid;
  }
}

// Latest Bölümü

#latest .latest-item img {
  min-height: 100%;
  width: 100%;
}

#latest button {
  max-height: 250px;
  padding: 0;
  overflow: hidden;
}

#latest .latest-item {
  position: relative;
}

#latest .latest-item:hover .overlay {
  opacity: 1;
  background: rgba(219, 151, 63, 0.9);
}

#latest .latest-item:hover i {
  opacity: 1;
  transform: none;
}

#latest .overlay {
  position: absolute;
  top:0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(219, 151, 63, 1);
  opacity: .5;
  color: #fff;
  transition: all .5s;
}

#latest .overlay i {
  transform: translateY(20px);
  transition: all .5s;
  opacity: 0;
  font-size: 2rem;

}

.footer-opacity {
  opacity: 0.7;
}

.bg-koyu-mavi {
background-color: #0a163a;
}

.mavi {
  color:#0c2981;
}

.bg-mavi {
  background-color:#0c2981;
}

.hakkimda {
  padding-top: 70px;
  padding-bottom: 50px;
  background-color: rgb(253, 253, 253);
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.image-gallery-slide{
  max-height: 400px !important;
}

.image-gallery {
  max-height: 400px !important;

}

.galeri {
  max-height: 400px;
}

.image-gallery-icon image-gallery-play-button {
  max-width: 800px !important;
}

.image-gallery-thumbnail-image {
  width: 80px !important;
  height: 50px !important;
}


.carousel-inner img {
  width: 100%;
  height: 100%
}

#custCarousel .carousel-indicators {
  position: static;
  margin-top: 20px
}

#custCarousel .carousel-indicators>li {
  width: 100px
}

#custCarousel .carousel-indicators li img {
  display: block;
  opacity: 0.5
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.75
}

.logo {
  max-height: 40px !important;
  display: flex;

}

